.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #31c27c;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.img-content {
  display: none;
  animation-name: none !important;
}

div.img-rotate  {
  animation-name: none !important;
}

.progress-bar {
  background-color: transparent !important;
}

.container-fluid {
  position: relative;
  user-select: none;
  cursor: pointer;
  background-image: url(https://cdn.bitrix24.site/bitrix/images/landing/business/1920x1280/img50.jpg);
  background-size: cover;
  -webkit-background-size: cover;
}

.container-fluid:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 2;
}

h1 {
  color: white;
  font-size: 3rem !important;
  font-weight: 700 !important;
  line-height: 102px;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 6px;
  text-size-adjust: 100%;
  text-transform: uppercase;
}

h2 {
  color: #d5cbcb;
}

.stilobit h2 {
  color: #ffffff;
  font-size: 2.1rem;
}

.stilobit h2  a {
  color: #31c27c !important;
}

.stilobit-right {
  text-align: right;
}

.col, .col-6, .col-3, .col-4, .col-12 {
  z-index: 3;
}

.list-group {
  overflow-y: auto;
}

.list-group-item.active {
  background-color: #31c27c !important;
  border-color: #31c27c !important;
}

.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  animation: none !important;
  -webkit-animation: none !important;
}

.react-jinke-music-player-mobile-cover .cover {
  animation: none !important;
  -webkit-animation: none !important;
}

@media (max-height: 456px) {
  .react-jinke-music-player-mobile-cover.text-center {
    display: none;
  }
}

@media (min-width: 1366px) {
  .h1-mobile {
    display: none !important;
    width: 0px;
    height: 0px;
  }
}

@media (max-width: 1365px) {
  .h1-mobile {
    color: #31c27c !important;
    font-size: 1rem !important;
  }
}